<template>
  <footer class="footer">
    <div class="content">
      <div class="content-left">
        <!-- <img src="../assets/imgs/LOGO_1024x1024.png" alt="" class="main-logo"> -->
        <div class="app-box">
          <div class="app-title">NOVEL</div>
          <div class="app-info">Enjoy This Moment
          </div>
        </div>
      </div>
      <!-- <div class="content-right">
        <div class="qrcode">
          <img src="../assets/imgs/downIos.png" alt="">
          <div class="qrcode-title">Download APP IOS</div>
        </div>
        <div class="qrcode">
          <img src="../assets/imgs/downAndroid.png" alt="">
          <div class="qrcode-title">Download APP Android</div>
        </div>
      </div> -->
    </div>
    <div class="info-box">
      <div class="info-box-left">
        <div class="bottom-nav-cell" @click="goOther('https://open.novelgo.net/site/user-agreement?language=en&id=1')">User Agreement</div><span style="color:#fff">|</span>
        <div class="bottom-nav-cell" @click="goOther('https://open.novelgo.net/site/privacy-policy?language=en&id=1')">Privacy Policy</div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="info-title">Contact Us : cs@techessentialsltd.com</div>
      <div class="info-title">Service Call：+ 852 53455609</div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
    goOther (url) {
      location.href = url
    }
  }
}
</script>

<style scoped>
.footer {
  background: #000;
  padding: 0 135px;
  height: 563px;
  padding-top: 67px;
}
.content {
  /* margin-top: 147px; */
  display: flex;
  justify-content: space-between;
}
.content-left {
  display: flex;
  padding-top: 4px;
}
.content-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 23px;
}
.app-title {
  color: #ffffff;
  line-height: 78px;
  font-size: 56px;
}
.main-logo {
  width: 128px;
  height: 128px;
  margin-right: 54px;
  border-radius: 24px;
}
.app-info {
  margin-top: 12px;
  width: 270px;
  height: 42px;
  font-size: 30px;
  font-weight: 400;
  color: #b1b1b1;
  line-height: 42px;
}
.qrcode {
  margin-left: 40px;
  text-align: center;
}
.qrcode img {
  width: 140px;
  height: 140px;
  border-radius: 15px;
}
.qrcode-title {
  margin-top: 25px;
  text-align: center;
  width: 168px;
  height: 33px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 33px;
}
.info-box {
  margin-top: 80px;
}
.info-box-left {
  text-align: left;
}
.bottom-nav-cell {
  cursor: pointer;
  display: inline-block;
  height: 32px;
  font-size: 22px;
  color: #ffffff;
  line-height: 32px;
  padding: 0 20px;
}
.bottom-nav-cell:nth-child(1) {
  padding-left: 0;
}
.bottom-nav-cell:nth-child(3) {
  border: none;
}
.info-box {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.info-box-right {
  width: 596px;
}
.info-title {
  font-size: 22px;
  color: #ffffff;
  line-height: 32px;
  display: inline-block;
  width: 33.3%;
}
.info-content {
  margin-top: 16px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 32px;
}
.address {
  width: 100%;
}
.footer-bottom {
  text-align: left;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 32px;
}
</style>
