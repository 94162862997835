<template>
  <div class="home">
    <header class="header">
      <img class="header-bg" src="../../assets/imgs/header.png" alt="">
      <div class="header-con">
        <!-- <img class="header-logo" src="../../assets/imgs/LOGO_1024x1024.png" alt=""> -->
        <p class="header-name">NOVEL</p>
        <p class="header-slogan">Reading Rommance <br> Stories and Books</p>
        <i class="header-small">Enjoy This Moment</i>
      </div>
    </header>
    <div class="nav">
      <div class="nav-books">
        <div v-for="(item, index) in list.booklist" :key="index" class="nav-book" @click="goBookDetails(item.book_id)">
          <img :src="item.cover" alt="">
          <ul>
            <li>{{item. name}}</li>
            <li>{{item.description}}</li>
            <li><img src="../../assets/imgs/see-yellow.png" alt=""> {{item.total_nums}} readers</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nav-bg" v-for="(item, index) in list.booklist1" :key="index" @click="goBookDetails(item.book_id)">
      <img class="nav-bgimg" src="../../assets/imgs/nav-bg.png" alt="">
      <div class="nav-bgA">
        <img class="nav-bgAimg" :src="item.cover" alt="">
        <ul>
          <li>
            <p>{{item.name}}</p>
          </li>
          <li>{{item.author}}</li>
          <li>{{item.description}}</li>
          <li><img src="../../assets/imgs/see-white.png" alt=""> {{item.total_nums}} readers</li>
        </ul>
      </div>
    </div>
    <div class="best">
      <h1>Best</h1>
      <div class="best-book">
        <ul v-for="(item, index) in list.booklist2" :key="index" @click="goBookDetails(item.book_id)">
          <li><img :src="item.cover" alt=""></li>
          <li>{{item.name}}</li>
          <li>{{item.description}}</li>
          <li><img src="../../assets/imgs/see-yellow.png" alt=""> {{item.total_nums}} readers</li>
        </ul>
      </div>
    </div>
    <div class="night">
      <img class="night-img" src="../../assets/imgs/night-bg.png" alt="">
      <div class="night-flant">
        <h1>One Night</h1>
        <div>
          <div class="night-flant1">
            <div>
              <div class="night-flant2" v-for="(item, index) in list.booklist5" :key="index" @click="goBookDetails(item.book_id)">
                <img :src="item.cover" alt="">
                <div>
                  <p>{{item.name}}</p>
                </div>
              </div>
            </div>
            <ul class="night-flant3">
              <li v-for="(item, index) in list.booklist3" :key="index"><img :src="item.cover" alt="" @click="goBookDetails(item.book_id)">
                <div>
                  <p>{{item.name}}</p>
                  <p>{{item.description}}</p>
                  <p><img src="../../assets/imgs/see-yellow.png" alt=""> {{item.total_nums}} readers</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mayAlso">
      <h1>You May Also Like</h1>
      <div class="mayAlso">
        <ul v-for="(item, index) in list.booklist4" :key="index" @click="goBookDetails(item.book_id)">
          <li><img :src="item.cover" alt=""></li>
          <li>{{item.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import booklist from './web.json'
export default {
  name: 'Home',
  data () {
    return {
      list: booklist[0]
    }
  },
  methods: {
    goBookDetails (val) {
      this.$router.push({
        path: '/BookDetails',
        query: {
          book_id: val
        }
      })
    }
  },
  components: {
  }
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav {
  width: 982px;
  margin: 63px auto;
  .nav-books {
    width: 100%;
    min-width: 982px;
    display: flex;
    .nav-book {
      margin-left: 20px;
      width: 480px;
      height: 203px;
      display: flex;
      img {
        width: 152px;
        height: 203px;
        border-radius: 10px;
      }
      ul {
        margin-left: 16px;
        height: 203px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li:nth-of-type(1) {
          font-size: 32px;
          font-weight: 500;
          color: #07061f;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        li:nth-of-type(2) {
          font-size: 19px;
          font-weight: 400;
          color: #07061f;
          line-height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        li:nth-of-type(3) {
          img {
            width: 25px;
            height: 15px;
          }
          margin-top: 25px;
          font-size: 25px;
          font-weight: 400;
          color: orange;
          line-height: 25px;
        }
      }
    }
  }
}
.nav-bg {
  position: relative;
  .nav-bgimg {
    width: 100%;
    height: 528px;
  }
  .nav-bgA {
    position: absolute;
    top: 51px;
    left: 50%;
    transform: translateX(-50%);
    width: 980px;
    display: flex;
    .nav-bgAimg {
      width: 300px;
      height: 410px;
      border: 1px dashed #fff;
      border-radius: 15px;
    }
    ul {
      width: 667px;
      margin-left: 74px;
      height: 410px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li:nth-of-type(1) {
        p {
          height: 38px;
          font-size: 32px;
          font-weight: 500;
          line-height: 38px;
          border-bottom: 2px solid #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      li:nth-of-type(2) {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 600;
        color: #ffffff;
        line-height: 25px;
      }
      li:nth-of-type(3) {
        margin-top: 44px;
        font-size: 25px;
        font-weight: 400;
        color: #ffffff;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
      li:nth-of-type(4) {
        img {
          width: 25px;
          height: 15px;
        }
        margin-top: 75px;
        font-size: 25px;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
      }
    }
  }
}
.best {
  h1 {
    margin-top: 63px;
    width: 100%;
    font-size: 48px;
    text-align: center;
    font-weight: 500;
    color: #07061f;
    line-height: 48px;
  }
  .best-book {
    max-width: 1200px;
    margin: 32px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ul {
      margin-left: 32px;
      margin-top: 48px;
      width: 306px;
      li:nth-of-type(1) {
        img {
          width: 100%;
          height: 410px;
          border-radius: 10px;
        }
      }
      li:nth-of-type(2) {
        margin-top: 16px;
        font-size: 24px;
        font-weight: 500;
        color: #07061f;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      li:nth-of-type(3) {
        margin-top: 16px;
        font-size: 19px;
        font-weight: 400;
        color: #07061f;
        line-height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      li:nth-of-type(4) {
        img {
          width: 25px;
          height: 15px;
        }
        margin-top: 17px;
        font-size: 19px;
        font-weight: 600;
        color: #ffa213;
        line-height: 19px;
      }
    }
  }
}
.night {
  width: 100%;
  margin-top: 64px;
  height: 814px;
  background-color: #f1f1f1;
  position: relative;
  .night-img {
    width: 100%;
    height: 814px;
  }
  .night-flant {
    width: 980px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    h1 {
      width: 100%;
      text-align: center;
      font-size: 48px;
      font-weight: 500;
      color: #07061f;
      line-height: 48px;
    }
    .night-flant1 {
      margin-top: 50px;
      display: flex;
      .night-flant2 {
        flex: 2;
        width: 410px;
        img {
          width: 100%;
          height: 520px;
          border: 1px dashed #666;
          border-radius: 8px;
        }
        p:nth-of-type(1) {
          margin-top: 24px;
          width: 100%;
          font-size: 32px;
          font-weight: 600;
          color: #07061f;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .night-flant3 {
      flex: 1;
      margin-left: 50px;
      li {
        display: flex;
        margin-top: 20px;
        img {
          width: 220px;
          height: 271px;
          border: 1px dashed #fff;
          border-radius: 8px;
        }
        p {
          margin-left: 10px;
          width: 100%;
          font-size: 32px;
          font-weight: 600;
          color: #07061f;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        p:nth-of-type(2) {
          margin: 25px;
          width: 100%;
          font-size: 24px;
          font-weight: 400;
          color: #07061f;
          line-height: 33px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        p:nth-of-type(3) {
          img {
            width: 25px;
            height: 15px;
          }
          margin-top: 30px;
          font-size: 19px;
          font-weight: 600;
          color: #ffa213;
          line-height: 19px;
        }
      }
      li:nth-of-type(1) {
        margin-top: 0px;
      }
    }
  }
}
.mayAlso {
  h1 {
    margin-top: 63px;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    color: #07061f;
    line-height: 48px;
  }
  .mayAlso {
    max-width: 1200px;
    margin: 63px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ul {
      margin-left: 32px;
      margin-top: 48px;
      li:nth-of-type(1) {
        img {
          width: 306px;
          height: 410px;
          border-radius: 10px;
        }
      }
      li:nth-of-type(2) {
        width: 306px;
        margin-top: 16px;
        font-size: 24px;
        font-weight: 500;
        color: #07061f;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.header {
  width: 100%;
  text-align: center;
  position: relative;
}
.header-bg {
  width: 100%;
  height: 872px;
}
.header-con {
  width: 100%;
  position: absolute;
  top: 212px;
}
.header-logo {
  width: 146px;
  height: 146px;
  border-radius: 15px;
}
.header-name {
  width: 100%;
  height: 38px;
  font-size: 38px;
  font-weight: 800;
  color: #ffffff;
  line-height: 38px;
}
.header-slogan {
  margin-top: 60px;
  width: 100%;
  height: 169px;
  font-size: 79px;
  font-weight: 400;
  color: #ffffff;
  line-height: 79px;
}
.header-small {
  margin-top: 20px;
  width: 100%;
  height: 32px;
  font-size: 28px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.8);
  line-height: 32px;
  letter-spacing: 5px;
}
</style>
